import React, { useEffect, useState } from 'react'
import '../../components/Sidebar/Sidebar.css'
import hiremeLogo from '../../assets/quikHireLogo.png'
import dashIconGrey from '../../assets/dashIconGrey.svg'
import jobRepoGray from '../../assets/jobRepoGray.svg'
import UserIconGrey from '../../assets/UserIconGrey.svg'
import helpLogoGrey from '../../assets/helpLogoGrey.svg'
import dashIconWhite from '../../assets/dashIconWhite.svg'
import sidebarLogo2 from '../../assets/sidebarLogo2.svg'
import sidebarLogo3 from '../../assets/sidebarLogo3.svg'
import sidebarLogo4 from '../../assets/sidebarLogo4.svg'
import ProfileIconGray from '../../assets/ProfileIconGray.svg'
import ProfileIconWhite from '../../assets/ProfileIconWhite.svg'
import bellIconGray from '../../assets/bellIconGray.svg'
import bellIconWhite from '../../assets/bellIconWhite.svg'
import managementIconGray from '../../assets/managementIconGray.svg'
import managementIconWhite from '../../assets/managementIconWhite.svg'
import arrow from '../../assets/downArrow.svg'
import sidebarLogoReportwhite from '../../assets/reportWhite.svg'
import sidebarLogoReportgray from '../../assets/reportGray.svg'
import createJob from '../../assets/Qcreate a job.svg'
import { useNavigate } from 'react-router-dom'
import { useProfileDropdownContext, useSelectedCardContext, useSelectedSubMenuContext, useSelectedSubMenuJobContext } from '../../Context/Context'
import { ModuleCards } from '../../constants/ModuleCards'
import { PortalUserRole } from '../../EnvoirnmentSetup/APIs'
import { IoMdArrowDropdown } from "react-icons/io";
import { IoMdArrowDropright } from "react-icons/io";
import companyListGray from '../../assets/companyListGray.svg'
import companyListWhite from '../../assets/companyListWhite.svg'
import cmGray from '../../assets/cmGray.svg'
import cmWhite from '../../assets/cmWhite.svg'
import myJobWhiteNew from '../../assets/myJobWhiteNew.svg'
import myJobGrayNew from '../../assets/myJobGrayNew.svg'
import companyJobsGray from '../../assets/companyJobsGray.svg'
import companyJobsWhite from '../../assets/companyJobsWhite.svg'
import qIcon from '../../assets/qIcon.svg'

const Sidebar = (props) => {


  const navigate = useNavigate()
  const { selectedCard, setSelectedCard } = useSelectedCardContext();
  const {subMenu, setSubMenu, subMenuColor, setSubMenuColor} = useSelectedSubMenuContext();
  const {subMenuJob, setSubMenuJob, subMenuColorMyJobs, setSubMenuColorMyJobs} = useSelectedSubMenuJobContext();
  const [mobileScreen,setMobileScreen] = useState(false)
  const {dropDownVisible,setDropDownVisible} = useProfileDropdownContext()


  const clearSession = () => {
    sessionStorage.removeItem("TablePageNumber");
    sessionStorage.removeItem("storedPageNumber");
    sessionStorage.removeItem("filterOption");
    sessionStorage.removeItem("pageEntriesLength");
    sessionStorage.removeItem("fromDate");
    sessionStorage.removeItem("toDate");
    sessionStorage.removeItem("DateSubmitStatus");
    sessionStorage.removeItem("searchTextString");
  };
  useEffect(()=>{
    if(window.screen.width<1024){
     setMobileScreen(true)
    }
    else{
     setMobileScreen(false)
    }
    if(selectedCard == ModuleCards?.Notification){
      setSubMenu(false)
      setSubMenuJob(false)
     }
   },[window.screen.width,selectedCard])
  return (
    <>{!mobileScreen?<div className='pDashSidebarOuterDiv'>
      <div className='pDashSidebarWrapper'>
      <div className='sidebarLogoDiv'>
        <img src={hiremeLogo} onClick={()=>{navigate('/dashboard');setSubMenu(false);setSubMenuJob(false);setDropDownVisible(false)}}/>
      </div>
      <div className='pDashNavContainer'>
        <div className='pDashSidebarItems' style={{backgroundColor:selectedCard==ModuleCards.Dashboard?"#007749":"#ffff",color:selectedCard== ModuleCards.Dashboard ?"#ffff":"#9D9D9D"}} onClick={()=>{setSelectedCard(ModuleCards.Dashboard);clearSession();navigate("/dashboard");setSubMenu(false);setSubMenuJob(false);}}>
          <div className="pDashSidebarImgDiv">
            <img src={selectedCard==ModuleCards.Dashboard? dashIconWhite:dashIconGrey} />
          </div>
          <div className="pDashSidebarNavs">
          Dashboard
          </div>
        </div>
        {/* <div className='pDashSidebarItems' style={{backgroundColor:selectedCard==ModuleCards.JobRepository?"#007749":"#ffff",color:selectedCard==ModuleCards.JobRepository?"#ffff":"#9D9D9D"}} onClick={()=>{setSelectedCard(ModuleCards.JobRepository);
        clearSession();
        navigate("/dashboard/joblist");
        setSubMenu(false)
        }}>
          <div className="pDashSidebarImgDiv">
            <img src={selectedCard==ModuleCards.JobRepository ? sidebarLogo2:jobRepoGray} />
          </div>
          <div className="pDashSidebarNavs">
          My Jobs
          </div>
        </div> */}


{/* <><div className='pDashSidebarItems' style={{marginBottom:0,backgroundColor:selectedCard==ModuleCards.JobRepository ? "#007749":"#ffff",color:selectedCard==ModuleCards.JobRepository ? "#ffff":"#9D9D9D"}} onClick={()=>{;
          clearSession();
          setSubMenuJob(!subMenuJob);
          setSubMenuColorMyJobs(0)
          setSubMenu(false)
          setDropDownVisible(false)
          }}>
          <div className="pDashSidebarImgDiv">
            <img src={selectedCard==ModuleCards.JobRepository ? sidebarLogo2: jobRepoGray} />
          </div>
          <div className="pDashSidebarNavs">
          Jobs &nbsp;<IoMdArrowDropdown className="sidebarDropdownArrow"/>
          </div>
        </div>
        {
           subMenuJob==true &&<>
           <div onClick={() => {clearSession();navigate("/dashboard/joblist");setSubMenuColorMyJobs(2);setSelectedCard(ModuleCards.JobRepository);setDropDownVisible(false)}} className='pDashSidebarItems' style={{backgroundColor:'#fff',padding:"7px",width:"80%",alignSelf:"end",marginBottom:0,borderRadius:0}}>
              <div className="pDashSidebarNavs hoverEffect" style={{color:subMenuColorMyJobs==2?"#007749":"#9D9D9D",fontWeight:subMenuColorMyJobs==2?500:"normal"}}>
              <IoMdArrowDropright className="sidebarDropdownRightArrow"/> &nbsp;My Jobs
              </div>
            </div>
            <div  onClick={() => {clearSession();navigate("/dashboard/management/alljobs");setSubMenuColorMyJobs(1);setSelectedCard(ModuleCards.JobRepository);setDropDownVisible(false)}} className='pDashSidebarItems' style={{backgroundColor:'#fff',padding:"5px 7px 7px 7px",width:"80%",alignSelf:"end",marginBottom:0,borderRadius:0}}>
              <div className="pDashSidebarNavs hoverEffect" style={{color:subMenuColorMyJobs==1?"#007749":"#9D9D9D",fontWeight:subMenuColorMyJobs==1?500:"normal"}}>
              <IoMdArrowDropright className="sidebarDropdownRightArrow"/> &nbsp;Company Jobs
              </div>
            </div>
            </>
        }
        </> */}
        <div className='pDashSidebarItems' style={{backgroundColor:selectedCard==ModuleCards.JobRepository ? "#007749":"#ffff",color:selectedCard==ModuleCards.JobRepository ? "#ffff":"#9D9D9D"}} onClick={()=>{setSelectedCard(ModuleCards.JobRepository );
          clearSession();
          setDropDownVisible(false)
          navigate("/dashboard/joblist");
          setSelectedCard(ModuleCards.JobRepository);
          }}>
          <div className="pDashSidebarImgDiv">
            <img src={selectedCard==ModuleCards.JobRepository ? myJobWhiteNew:myJobGrayNew} />
          </div>
          <div className="pDashSidebarNavs">
          My Postings
          </div>
        </div>
        <div className='pDashSidebarItems' style={{backgroundColor:selectedCard==ModuleCards.CompanyJobs ? "#007749":"#ffff",color:selectedCard==ModuleCards.CompanyJobs ? "#ffff":"#9D9D9D"}} onClick={()=>{
          clearSession();
          setSelectedCard(ModuleCards.CompanyJobs);
          setDropDownVisible(false)
          navigate("/dashboard/management/alljobs");
          }}>
          <div className="pDashSidebarImgDiv">
            <img src={selectedCard==ModuleCards.CompanyJobs ? companyJobsWhite:companyJobsGray} />
          </div>
          <div className="pDashSidebarNavs">
          All Jobs
          </div>
        </div>
        <div className='pDashSidebarItems' style={{backgroundColor:selectedCard==ModuleCards.ApplicantRepository ? "#007749":"#ffff",color:selectedCard==ModuleCards.ApplicantRepository ? "#ffff":"#9D9D9D"}} onClick={()=>{setSelectedCard(ModuleCards.ApplicantRepository );
        clearSession();
          navigate('/dashboard/applicantRepository');
          setSubMenu(false)
          setSubMenuJob(false);
          setDropDownVisible(false)
          // navigate('/dashboard/management/userManagement')
          }}>
          <div className="pDashSidebarImgDiv">
            <img src={selectedCard==ModuleCards.ApplicantRepository ? sidebarLogo3:UserIconGrey} />
          </div>
          <div className="pDashSidebarNavs">
          Applicants
          </div>
        </div>
        
        {/* <div className='pDashSidebarItems' style={{backgroundColor:selectedCard==ModuleCards.AllJobs?"#007749":"#ffff",color:selectedCard==ModuleCards.AllJobs?"#ffff":"#9D9D9D"}} onClick={()=>{setSelectedCard(ModuleCards.AllJobs);
        clearSession();
        navigate("/dashboard/management/alljobs")
        setSubMenu(false)
        setSubMenuJob(false);
        }}>
          <div className="pDashSidebarImgDiv">
            <img src={selectedCard==ModuleCards.AllJobs ? sidebarLogo2:jobRepoGray} />
          </div>
          <div className="pDashSidebarNavs">
          All Jobs
          </div>
        </div> */}
        <div className='pDashSidebarItems' style={{backgroundColor:selectedCard==ModuleCards.CompanyList?"#007749":"#ffff",color:selectedCard==ModuleCards.CompanyList?"#ffff":"#9D9D9D"}} onClick={()=>{setSelectedCard(ModuleCards.CompanyList);
        clearSession();
        navigate("/dashboard/companyList")
        setSubMenu(false)
        setDropDownVisible(false)
        }}>
          <div className="pDashSidebarImgDiv">
            <img src={selectedCard==ModuleCards.CompanyList ? cmWhite:cmGray} />
          </div>
          <div className="pDashSidebarNavs">
          Companies
          </div>
        </div>
        <div className='pDashSidebarItems' style={{backgroundColor:selectedCard==ModuleCards.Report?"#007749":"#ffff",color:selectedCard==ModuleCards.Report?"#ffff":"#9D9D9D"}} onClick={()=>{setSelectedCard(ModuleCards.Report);
        clearSession();
        navigate("/dashboard/total-usage-summary")
        setSubMenu(false)
        setSubMenuJob(false);
        setDropDownVisible(false)
        }}>
          <div className="pDashSidebarImgDiv">
            <img src={selectedCard==ModuleCards.Report ? sidebarLogoReportwhite:sidebarLogoReportgray} />
          </div>
          <div className="pDashSidebarNavs">
          Total Usage Summary
          </div>
        </div>
        {PortalUserRole=="ROLE_MANAGER" && <><div className='pDashSidebarItems' style={{marginBottom:0,backgroundColor:selectedCard==ModuleCards.Management ? "#007749":"#ffff",color:selectedCard==ModuleCards.Management ? "#ffff":"#9D9D9D"}} onClick={()=>{;
          clearSession();
          setSubMenu(!subMenu);
          setSubMenuColor(0)
          setDropDownVisible(false)
          }}>
          <div className="pDashSidebarImgDiv">
            <img src={selectedCard==ModuleCards.Management ? managementIconGray: managementIconWhite} />
          </div>
          <div className="pDashSidebarNavs">
          Team &nbsp;<span id={subMenu==true?'downArrow':'rightArrow'}>&gt;</span>
          </div>
        </div>
        {
           subMenu==true &&<>
            <div  onClick={() => {navigate("/dashboard/management/alljobs");setSubMenuColor(1);setSelectedCard(ModuleCards.Management);setDropDownVisible(false)}} className='pDashSidebarItems' style={{backgroundColor:'#fff',padding:"7px",width:"75%",alignSelf:"end",marginBottom:0,borderRadius:0}}>
              <div className="pDashSidebarNavs hoverEffect" style={{fontSize:"13px",color:subMenuColor==1?"#007749":"#9D9D9D",fontWeight:subMenuColor==1?500:"normal"}}>
              &gt; &nbsp;Search Job
              </div>
            </div>
            <div onClick={() => {navigate("/dashboard/management/userManagement");setSubMenuColor(2);setSelectedCard(ModuleCards.Management);setDropDownVisible(false)}} className='pDashSidebarItems' style={{backgroundColor:'#fff',padding:"7px",width:"75%",alignSelf:"end",marginBottom:0,borderRadius:0}}>
              <div className="pDashSidebarNavs hoverEffect" style={{fontSize:"13px",color:subMenuColor==2?"#007749":"#9D9D9D",fontWeight:subMenuColor==2?500:"normal"}}>
              &gt; &nbsp;User Admin
              </div>
            </div>
            <div onClick={()=>{navigate('/dashboard/management/reportdashboard');setSubMenuColor(3);setSelectedCard(ModuleCards.Management);setDropDownVisible(false)}} className='pDashSidebarItems' style={{backgroundColor:'#fff',padding:"7px",width:"75%",alignSelf:"end",marginBottom:0,borderRadius:0}}>
              <div className="pDashSidebarNavs hoverEffect" style={{fontSize:"13px",color:subMenuColor==3?"#007749":"#9D9D9D",fontWeight:subMenuColor==3?500:"normal"}}>
              &gt; &nbsp;Reports
              </div>
            </div>
            </>
        }
        </>}
        {/* <div className='pDashSidebarItems' style={{backgroundColor:selectedCard==ModuleCards.profile ? "#007749":"#ffff",color:selectedCard==ModuleCards.profile ? "#ffff":"#9D9D9D"}} onClick={()=>{setSelectedCard(ModuleCards.profile );
        clearSession();
          navigate('/dashboard/profile')}}>
          <div className="pDashSidebarImgDiv">
            <img src={selectedCard==ModuleCards.profile ? ProfileIconWhite:ProfileIconGray} />
          </div>
          <div className="pDashSidebarNavs">
          Profile
          </div>
        </div> */}
        {/* <div className='pDashSidebarItems' style={{backgroundColor:selectedCard==ModuleCards.Notification?"#007749":"#ffff",color:selectedCard==ModuleCards.Notification?"#ffff":"#9D9D9D"}} onClick={()=>{setSelectedCard(ModuleCards.Notification);
          clearSession();
          navigate('/dashboard/notification')}}>
          <div className="pDashSidebarImgDiv">
            <img src={selectedCard==ModuleCards.Notification?bellIconWhite:bellIconGray} />
          </div>
          <div className="pDashSidebarNavs">
          Notifications
          </div>
        </div> */}
      </div>
      </div>
      <div className='pDashSidebarBottomDiv'>
        {/* <div className='pDashSidebarCreateJobs' onClick={()=>navigate('/dashboard/createJobs')}>
           <div className='pDashPlusIcon'>+</div>
           <div className='pDashCreateJobsTxt'>Create a job</div>
        </div> */}
           {/* <img src={createJob} className='pDashSidebarCreateJobs' onClick={()=>{setSelectedCard(2);navigate('/dashboard/createJobs');setDropDownVisible(false)}} alt='Something went wrong'/> */}
           <div className="pDashSidebarCreateJobs" id='createJob' onClick={()=>{setSelectedCard(2);navigate('/dashboard/createJobs');setDropDownVisible(false)}}>
          <img src={qIcon} />Create a job
         </div>
        {/* <div className='pDashSidebarCopyright' style={{border:"1px solid red"}}> */}
        <div className='pDashSidebarCopyright' style={{textAlign:"center"}}>&copy; {new Date().getFullYear()} QuikHire.ai</div>
          <div className='pDashSidebarCopyright' style={{textAlign:"center"}}>All rights reserved.</div>
        {/* </div> */}
      </div>
    </div>:<></>}</>
  )
}

export default Sidebar