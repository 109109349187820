import React, { useEffect, useState } from "react";
import "./DropdownWithSelectPicker.css";
import Lottie from "lottie-react";
import Oops from "../../assets/Opps.json";
import Modal from "react-modal";
const DropdownWithSelectPicker = ({
  label,
  isRequired,
  labelStyle = {},
  placeholderText = "",
  entryInput = "",
  entries = [],
  suggestions = [],
  setEntryInput,
  setEntries,
  setSuggestions,
  handleEmailChange,
  maxRoles = 200,
  InputSty,
  CustomeEntryShow = false,
  onFocus,
  onBlur,
  defaultentries = [],
  ParentFieldWrapper={},
  customeStyleForSuggestionList={},
  paddingLeftZeroRequired,
  marginRequired=true,
  disabled=false,
  lineHeight=false
}) => {
  const [isEntryAvailable, setIsEntryAvailable] = useState(false);
  const [popupMsg, setPopupMsg] = useState("")
  const [errPopup, setErrPopup] = useState(false)
  const atLeastOneCharacterRegex = /(?=.*[a-zA-Z])/;
// console.log({suggestions})
const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    minWidth: "30%",
    borderRadius: "25px",
    padding: "10px",
    zIndex:100000
  },
};
  const handleAddEntry = (item) => {
    // if (entryInput.trim() !== '') {
      console.log({item})
    let allEntries = entries;
    allEntries.push(item);
    console.log(allEntries);
    setEntries([...allEntries]);
    setSuggestions([]);
    setEntryInput("");
    // }
  };

  const handleDeleteEntry = (index) => {
    const newEntries = [...entries];
    newEntries.splice(index, 1);
    setEntries(newEntries);
  };

  useEffect(() => {
    if (entryInput.includes(',') && CustomeEntryShow) {
      const entriesArray = entryInput?.split(',');
      let previousEntries = [...entries]
      entriesArray.forEach((i)=>{
        if(i.trim().length>0 && !suggestions?.some(
          (obj) =>{
           return obj.name?.trim().toLowerCase() == i?.trim().toLowerCase()}
        ) ){
       return previousEntries.push({ name: i?.trim(), Id: "" })}
      })
      setEntries(previousEntries)
      setEntryInput("");

  } else {
    if (entries.some((obj) => obj.name == entryInput)) {
      setIsEntryAvailable(true);
    } else {
      setIsEntryAvailable(false);
    }
  }
   
  }, [entryInput]);

  return (
    <div style={{width:"100%",position:'relative'}}>
      {label ? (
        <label className="defaultLabelStyle" style={labelStyle}>
          {label}
          {isRequired ? (
            <span style={{ color: "#BC0000", fontWeight: "bold" }}>{"*"}</span>
          ) : (
            <></>
          )}
        </label>
      ) : (
        <></>
      )}
      <div className="searchboxWrapper" style={{gap:'6px',position:'relative',overflowY:"auto",alignItems:'flex-start',height:'auto',...ParentFieldWrapper,}}>
        {entries?.map((i, index) => (
          <div
            key={index}
            style={{
              display: "flex",
              alignItems: "center",
              backgroundColor: "#E8EFFF",
              height:/* entries.length >= 6 ? "14px" : entries.length >= 8 ? "14px" : */"16px",
              fontSize:/* entries.length >= 6 ? "9px" : entries.length >= 8 ? "10px" : */ "13px",
              borderRadius:'4px',
              padding:'4px',
              gap:'6px',
            }}
          >
            <p style={{maxWidth:"250px",overflow:"hidden",textOverflow:"ellipsis",whiteSpace:"nowrap", }}>
              {i.name}
            </p>
            {!disabled && <span
              style={{ color: "red",cursor:'pointer' }}
              onClick={() => handleDeleteEntry(index)}
            >
              X
            </span>}
          </div>
        ))}

        {maxRoles > entries.length && (
          !disabled && <input style={{margin:marginRequired ? "0 10px": "0px",paddingLeft:paddingLeftZeroRequired=="true"?0:"",lineHeight:lineHeight && "25px"}} type="text" className="searchfield"  value={entryInput}
          onChange={handleEmailChange}
          placeholder={placeholderText}/>
        )}
      </div>
        {entryInput.length > 2 &&<div className="SuggestionList" style={{top:'100%',...customeStyleForSuggestionList}}>
          {suggestions?.length>0 && suggestions?.map((item, index) => {
          return  <p
          style={{
            padding: 6,
            backgroundColor: "#E0EEFF",
            width: "100%",
            fontSize: 16,
            borderRadius: 6,
          }}
            key={index}
              onClick={() => {
                console.log("red", item);
                if (
                  !entries.some(
                    (obj) =>
                      obj?.name?.trim().toLowerCase() ==
                      item?.name?.trim().toLowerCase()
                  )
                ) {
                  handleAddEntry(item);
                } else {
                  setSuggestions([]);
                  setEntryInput("");
                  setPopupMsg("This entry has already been added.");
                  setErrPopup(true)
                }
              }}
            >
              {item.name}
            </p>;
          })}
          {!suggestions?.some(
            (obj) =>
              obj.name?.trim().toLowerCase() == entryInput?.trim().toLowerCase()
          ) &&
            !isEntryAvailable &&
            atLeastOneCharacterRegex.test(entryInput?.trim()) &&
            CustomeEntryShow &&
            entryInput.length > 0 &&
            entryInput?.trim().length > 0 && (
              <div style={{ marginVertical: 3 }}>
                <p
                  style={{
                    margin:"0px",
                    padding: "6px",
                    backgroundColor: "#E0EEFF",
                    width: "100%",
                    fontSize: "16px",
                    borderRadius: "6px",
                  }}
                  onClick={() => {
                    handleAddEntry({ name: entryInput?.trim(), Id: "" });
                  }}
                >
                  {entryInput?.trim()}
                </p>
              </div>
            )}
        </div>}
        <Modal
        isOpen={errPopup}
        onRequestClose={() => setErrPopup(false)}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv">
            <p onClick={() => setErrPopup(false)} className="delAccModalCrossPara">×</p>
          </div>
        </div>
        <div style={{marginTop:-20}}>
          <Lottie
            animationData={Oops}
            loop={true}
            className="delAccModalErrLottie"
          />
        </div>
        <div className="delAccModalErrMsg" style={{padding:0}}>
          {popupMsg}
        </div>
        <div className="okBtnModal">
          <button onClick={()=>setErrPopup(false)}>OK</button>
        </div>
      </Modal>
    </div>
  );
};

export default DropdownWithSelectPicker;
