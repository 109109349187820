import { portalRole, selectedProfileConstant } from "./EnvoirnmentConstants";
import { ProfileConstant, RoleConstant } from "./ProfileConstants";
import { getActivProfile, getWebProfileForDeepLinking } from "./profiles";

export const PortalUserRole = portalRole

export const selectedWebProfile = getWebProfileForDeepLinking(selectedProfileConstant);
export const LoginAutonavigateEnv = selectedProfileConstant;
const baseURL = getActivProfile(selectedProfileConstant);
// console.log("Active env", baseURL);
export const Url = {
  SendOtp: baseURL + "sms/otp",
  DeleteUserAccount: baseURL + "user/remove",
  CountryCodes: baseURL + "countrycodes",
  contact: baseURL + "public/enquiry",
  UserSignup: baseURL + "user/sign-up",
  UserLogin: baseURL + "login",
  countrys: baseURL + "countrycode", //get
  getuser: baseURL + "user",
  getAllJobs: baseURL + "job",
  getJobDetailsByJobId: baseURL + "job/{id}",
  getCreatedJobsList: baseURL + "job/user",
  searchJobByKeywords: baseURL + "job/search/{keyword}",
  searchDesignation: baseURL + "designation/{string}",
  searchKeywords: baseURL + "skill/{string}",
  searchLocation: baseURL + "state/{state}/countrycode/{countrycodeId}",
  ChangeJobPostStatus: baseURL + "user/job",
  getJobDetailsByJobIDApplicant: baseURL + "user/job/{jobID}",
  SendOTP: baseURL + "sms/otp",
  GetExperienceList: baseURL + "public/experience",
  Preferances: baseURL + "user/preference",
  GetAllFilterdJob: baseURL + "user/job/filter",
  SearchFilteredJobByKeywords: baseURL + "user/job/filter/{keyword}",
  GetAllInterestedJob: baseURL + "user/job/intrested",
  SearchInterestedJobByKeywords: baseURL + "user/job/intrested/{keyword}",
  SendFeedBack: baseURL + "feedback",
  GetNotifications: baseURL + "notification",
  GetPostApplicationStatus: baseURL + "job/{jobId}/applicant",
  GetApplyiedApplicantList: baseURL + "job/applicant",
  searchApplicants: baseURL + "job/applicant/{jobId}/intrested/{text}",
  deleteJob: baseURL + "job/{jobId}",
  GetAllDepartments: baseURL + "department",
  searchDepartment: baseURL + "department/{keyword}",
  AlldataReport: baseURL + "admin/quick-totals",
  getReportByDates: baseURL + "admin/quick-totals/from/{fromDate}/to/{toDate}",
  getposterJobswithoutDatefilter: baseURL + 'job/user/page-size/{pageSize}/page-number/{pageNumber}/sort/{field}',
  getposterJobswithDatefilter: baseURL + 'job/user/page-size/{pageSize}/page-number/{pageNumber}/from/{from}/to/{to}/sort/{field}',
  searchposterJobswithoutDatefilter: baseURL + 'job/user/page-size/{pageSize}/page-number/{pageNumber}/search/{keyword}/sort/{field}',
  searchposterJobswithDatefilter: baseURL + 'job/user/page-size/{pageSize}/page-number/{pageNumber}/from/{from}/to/{to}/search/{keyword}/sort/{field}',
  getPosterDashboardDetails: baseURL + 'poster/from/{fromDate}/to/{toDate}',
  getApplicantrepositoryList:baseURL + 'user/applicant/page-size/{pageSize}/page-number/{pageNumber}/sort/{field}',
  searchApplicantfromrepositoryList:baseURL + 'user/applicant/page-size/{pageSize}/page-number/{pageNumber}/search/{keyword}/sort/{field}',
  mobileNoVerification: baseURL + 'public/user/countrycode/{countrycodeId}/username/{mobile_number}/iam/'+PortalUserRole,
  forgotPwd : baseURL + 'user/forgot-password',
  registerUser : baseURL + 'user/sign-up',
  changePassword: baseURL + 'user/update-password',
  NotificationsCount: baseURL + 'notification/count',
  AddTeamMate: baseURL + 'manager/user',
  GetAllTeamMates: baseURL + 'manager/team',
  // getLeaderJobswithoutDatefilter: baseURL + 'manager/job/page-size/{pageSize}/page-number/{pageNumber}/sort/{field}',
  // getLeaderJobswithDatefilter: baseURL + 'manager/job/page-size/{pageSize}/page-number/{pageNumber}/from/{from}/to/{to}/sort/{field}',
  // searchLeaderJobswithoutDatefilter: baseURL + 'manager/job/page-size/{pageSize}/page-number/{pageNumber}/search/{keyword}/sort/{field}',
  // searchLeaderJobswithDatefilter: baseURL + 'manager/job/page-size/{pageSize}/page-number/{pageNumber}/from/{from}/to/{to}/search/{keyword}/sort/{field}',
  deletePoster : baseURL + 'manager/user/{userId}',
  getJobPostingRecruiterSummaryReportdata : baseURL + 'manager/date-summary/from/{from}/to/{to}',
  getJobPostingSummaryAcrossCompanyReportdata: baseURL + 'manager/weekly-team-report',
  JPRSdropdown : baseURL + 'manager/team/dropdown',
  getJobPostingperRecruiter: baseURL + 'manager/user/{userId}/report/{days}',

  getAdminJobswithoutDatefilter: baseURL + 'admin/job/page-size/{pageSize}/page-number/{pageNumber}/sort/{field}',
  getAdminJobswithDatefilter: baseURL + 'admin/job/page-size/{pageSize}/page-number/{pageNumber}/from/{from}/to/{to}/sort/{field}',
  searchAdminJobswithoutDatefilter: baseURL + 'admin/job/page-size/{pageSize}/page-number/{pageNumber}/search/{keyword}/sort/{field}',
  searchAdminJobswithDatefilter: baseURL + 'admin/job/page-size/{pageSize}/page-number/{pageNumber}/from/{from}/to/{to}/search/{keyword}/sort/{field}',
  createJobType : baseURL + 'job/job-type',
  createJobWorkMode : baseURL + 'job/work-mode',
  searchComapny : baseURL + 'company/',
  shortListCandidate : baseURL + 'user/job',
  otpVerify: baseURL + 'sms/otp/varify',
  getCompanyList : baseURL + 'company',
  getCompanyListDropdown : baseURL + 'company/dropdown',
  downloadExcel : baseURL + 'manager/excel/',
  downloadExcelDateWise : baseURL + 'manager/excel/{key}/from/{fromDate}/to/{toDate}',
  compnaySize: baseURL + 'company/strength',
  companyIndustry: baseURL + 'industry',
  getServicesOptions : baseURL +"service/industry/{industryid}",
  searchserviceKeywords: baseURL + "service/{string}/industry/{industryId}",
  UploadDocumentToBucket: baseURL + 's3/public/{folderName}/upload',
  deleteCompany: baseURL + 'company/{companyId}',
  acceptTermsAndConditions : baseURL + 'user/terms',
  getAllSkills: baseURL + 'skill',
  getAllLocations: baseURL + 'state',
  purchasedLicenses : baseURL + 'payment/history',
  scheduleOrShortlist: baseURL + 'user/job',
};
