import React, { useEffect, useState } from 'react'
import '../../components/DashboardHeader/DashboardHeader.css'
import bellIcon from '../../assets/bellIcon.svg'
import profileIcon from '../../assets/profileIcon.svg'
import downArrow from '../../assets/downArrow.svg'
import feedbackIcon from '../../assets/feedbackIcon.svg'
import logoutBtn from '../../assets/LogoutBtn.svg'
import { useNavigate } from 'react-router-dom'
import Modal from "react-modal";
import Lottie from "lottie-react";
import LogOutLogo from '../../assets/logOutLogo.svg'
import { PortalUserRole, Url } from '../../EnvoirnmentSetup/APIs'
import completed from '../../assets/Success.json'
import Oops from "../../assets/Opps.json";
import userProIcon from '../../assets/userProIcon.svg'
import logOutLogo from '../../assets/logoutBtnNew.svg'
import bellIconWithoutDot from '../../assets/bellIconWithoutDot.svg'
import { useNotificationContext, useProfileDropdownContext, useSelectedCardContext, useSelectedSubMenuContext, useSelectedSubMenuJobContext } from '../../Context/Context'
import helpIcon from '../../assets/HelpandReport.svg'
import { RxHamburgerMenu } from "react-icons/rx";
import QuikHireLogo from '../../assets/quikHireLogo.png'
import { ModuleCards } from '../../constants/ModuleCards'
import dashIconWhite from '../../assets/dashIconWhite.svg'
import sidebarLogo2 from '../../assets/sidebarLogo2.svg'
import sidebarLogo3 from '../../assets/sidebarLogo3.svg'
import jobRepoGray from '../../assets/jobRepoGray.svg'
import UserIconGrey from '../../assets/UserIconGrey.svg'
import dashIconGrey from '../../assets/dashIconGrey.svg'
import { IoMdArrowDropdown } from "react-icons/io";
import { IoMdArrowDropright } from "react-icons/io";
import managementIconGray from '../../assets/managementIconGray.svg'
import managementIconWhite from '../../assets/managementIconWhite.svg'
import createJob from '../../assets/Qcreate a job.svg'
import sidebarLogoReportwhite from '../../assets/reportWhite.svg'
import sidebarLogoReportgray from '../../assets/reportGray.svg'
import companyListGray from '../../assets/companyListGray.svg'
import companyListWhite from '../../assets/companyListWhite.svg'
import cmGray from '../../assets/cmGray.svg'
import cmWhite from '../../assets/cmWhite.svg'
import myJobWhiteNew from '../../assets/myJobWhiteNew.svg'
import myJobGrayNew from '../../assets/myJobGrayNew.svg'
import companyJobsGray from '../../assets/companyJobsGray.svg'
import companyJobsWhite from '../../assets/companyJobsWhite.svg'
import qIcon from '../../assets/qIcon.svg'

const DashboardHeader = (props) => {
  console.log("propsData", props);
  const navigate = useNavigate()

  const {notificationCount} = useNotificationContext()
  const [lgOutPopup, setLgOutPopup] = useState(false)
  const [fdbckPopup, setFdbckPopup] = useState(false)
  const [successPopup, setSuccessPopup] = useState(false)
  const [FailmodalIsOpen, setFailmodalIsOpen] = useState(false);
  const [error, setError] = useState("");
  const [popupMsg, setPopupMsg] = useState("")
  const [fdbck, setFdbck] = useState("")
  const [newNotification,setNewNotification] = useState(0)
  const [openSidebar,setOpenSidebar] = useState(false)
  const { selectedCard, setSelectedCard } = useSelectedCardContext();
  const {subMenu, setSubMenu, subMenuColor, setSubMenuColor} = useSelectedSubMenuContext();
  const {subMenuJob, setSubMenuJob, subMenuColorMyJobs, setSubMenuColorMyJobs} = useSelectedSubMenuJobContext();
  const {dropDownVisible,setDropDownVisible} = useProfileDropdownContext()

  const handleLogout = () => {
    sessionStorage.clear()
    navigate('/login')
  }

  useEffect(()=>{
     try{
       fetch(Url.NotificationsCount,{
        method:"GET",
        headers:{
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': "Bearer " + sessionStorage.getItem("token"),
        }
       }).then((resp)=>resp.json())
       .then((res)=>{
        console.log("notifications count response",res);
        setNewNotification(res?.DATA)
       })
       .catch((e)=>{
         console.log(e);
       })
     }
     catch(e){
       console.log(e);
     }
  },[notificationCount]);
  
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "40%",
      borderRadius: "25px",
      padding: "10px",
    },
  };
  const sidebarStyle = {
    content: {
      position:"absolute",
      top:"0%",
      left:"0%",
      width:"70%",
      border:"none",
      height:"100%",
      borderRadius:"none",
      padding:"10px 0"
    },
  };
  const customStyles1 = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "60%",
      maxWidth:"88%",
      borderRadius: "25px",
      padding: "10px",
    },
  };
  const customStylesDeleteModal = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "40%",
      borderRadius: "25px",
      padding: "10px",
    },
  };
  const handleInputChange = (e) => {
    setFdbck(e?.target?.value)
  }

  function openFailModal() {
    setFailmodalIsOpen(true);
  }

  function closeFailModal() {
    setFailmodalIsOpen(false);
  }

  const handleFdbckSend = () => {
    let msg = {
      feedback: fdbck,
    }
    let token = sessionStorage.getItem("token");
    if(!(!!fdbck?.trim())){
      setError("Feedback cannot be empty.");
      setFdbckPopup(false)
      openFailModal();
    }
    else if(fdbck?.trim()?.length<15){
      setError("Feedback should contain atleast 15 characters.");
      setFdbckPopup(false)
      openFailModal();
    }
    else{
    try {
      fetch(Url.SendFeedBack, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify(msg)
      }).then((resp) => resp.json())
        .then((res) => {
          console.log("response feedback api", res);
          if (res?.SUCCESS == true && res?.DATA == true) {
            setSuccessPopup(true);
            setPopupMsg("Feedback sent successfully.")
            setFdbck("")
          } else {
            setError(res?.message);
            setFdbckPopup(false)
            openFailModal();
          }
        })
        .catch((e) => {
          console.log(e);
        })
    }
    catch (e) {
      console.log(e);
     }
   }
  }
  let userName = JSON.parse(sessionStorage.getItem('userName'))
  
  const clearSession = () => {
    sessionStorage.removeItem("TablePageNumber");
    sessionStorage.removeItem("storedPageNumber");
    sessionStorage.removeItem("filterOption");
    sessionStorage.removeItem("pageEntriesLength");
    sessionStorage.removeItem("fromDate");
    sessionStorage.removeItem("toDate");
    sessionStorage.removeItem("DateSubmitStatus");
    sessionStorage.removeItem("searchTextString");
  };

  const handleProfile = () => {
    setDropDownVisible(false)
    clearSession();
    navigate('/dashboard/profile')
  }
  const handleNotifications = () => {
    setDropDownVisible(false)
    setSelectedCard(ModuleCards?.Notification)
    setSubMenu(false)
    setSubMenuJob(false)
    clearSession();
    navigate('/dashboard/notification')
  }

  return (
    <div>
      <div className='pDashHeaderOuterDiv'>

      <div className="sidebar-icon">
      {window.screen.width<1024 && <RxHamburgerMenu style={{color:'#007749', fontSize:'20px'}} onClick={()=>{setOpenSidebar(!openSidebar);setDropDownVisible(false)}} data-testid='RxHamburgerMenu'/>}

        </div>
        <div className='pDashHeaderActionDiv'>
            <img className='pDashHeaderImages' src={feedbackIcon} onClick={() => {setFdbckPopup(true);setDropDownVisible(false)}} alt='feedbackImg'/>
            <img  className='pDashHeaderImages' onClick={()=>window.open('https://www.quikhire.ai/uat/faq.php','_blank')} alt='helpicon' src={helpIcon}/>
            <span className='bellIconDiv'>
            <img className='pDashHeaderImages' onClick={handleNotifications} src={bellIconWithoutDot} alt='bellIconWithoutdot'/>
            {newNotification != 0? <span className='notificationCountDiv' onClick={handleNotifications}>{newNotification}</span> : <></>}
            </span>
            <div className='userDetailsDiv' onClick={()=>setDropDownVisible(!dropDownVisible)}>
            <img className='pDashHeaderImages'src={profileIcon} />
            <span className='headerUserNameSpan' title={userName}>{userName}</span>
            <img className='dwnArrowUserProfile' src={downArrow}/>

            {
              dropDownVisible && 
              <div className='dropDownDiv'>
                <div className='drpDwnItem' onClick={handleProfile}>
                  <img src={userProIcon} className='drpDwnImg'/>
                  <span>View Profile</span>
                </div>
                <div className='drpDwnItem' onClick={()=>{setLgOutPopup(true);setDropDownVisible(false)}}>
                <img src={logOutLogo} className='drpDwnImg'/>
                  <span>Logout</span>
                </div>
              </div>
            }
            </div>
          {/* <div className='pDashHeadLogoutBtnDiv' onClick={() => setLgOutPopup(true)}>
            <img src={logoutBtn} />
            <span>Logout</span>
          </div> */}
        </div>

      </div>
      <Modal
        isOpen={lgOutPopup}
        onRequestClose={() => setLgOutPopup(false)}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv" style={{ backgroundColor: "#FFF4F4" }}>
            <p onClick={() => setLgOutPopup(false)} className="delAccModalCrossPara" style={{ color: "#BC0000" }}>×</p>
          </div>
        </div>
        <div className='logoutLogoDiv'>
          <img src={LogOutLogo} alt='Logoutbutton'/>
        </div>
        <div className="delAccModalErrMsg" style={{ fontWeight: "normal",marginBottom:0}}>
        Would you like to logout?
        </div>
        <div className="okBtnModal" style={{ display: "flex", gap: "2%" }}>
          <button onClick={() => setLgOutPopup(false)} style={{ backgroundColor: "#ffff", border: "0.5px solid #007749", color: "#007749",cursor:"pointer" }}>Cancel</button>
          <button style={{ backgroundColor: "#BC0000",cursor:"pointer" }} onClick={handleLogout} >Logout</button>
        </div>
      </Modal>

      <Modal
        isOpen={fdbckPopup}
        onRequestClose={()=>{setFdbckPopup(false);setFdbck("")}}
        ariaHideApp={false}
        style={customStyles1}
        shouldCloseOnOverlayClick={false}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv">
            <p onClick={()=>{setFdbckPopup(false);setFdbck("")}} className="delAccModalCrossPara" data-testid='closefeedbackModal'>×</p>
          </div>
        </div>
        <div className='fdbckTitleDiv'>Feedback</div>
        <p className='fdbckPara'>Submit Your Feedback<span style={{ color: "#BC0000" }}>*</span></p>
        <div className='fdbckTxtAreaDiv'>
          <textarea maxLength={256} className='fdbckTxtArea' value={fdbck} placeholder='Write your comments/questions/features requests/issues here.' onChange={(e) => handleInputChange(e)}></textarea>
        </div>
        <div className='fdbckTxtValidator'>
          <p className='fdbckParaNew'>*Feedback should contain atleast 15 characters.</p>
          <span>{fdbck.length}/256</span>
        </div>
        <div className='fdbckButtons'>
          <button className='fdbckBtnOne' onClick={()=>{setFdbckPopup(false);setFdbck("")}}>Cancel</button>
          <button className='fdbckBtnTwo' onClick={handleFdbckSend}>Send</button>
        </div>
      </Modal>
      <Modal
        isOpen={FailmodalIsOpen}
        onRequestClose={closeFailModal}
        ariaHideApp={false}
        style={customStylesDeleteModal}
        shouldCloseOnOverlayClick={false}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="ApplicantRepodelAccModal1">
          <div className="ApplicantRepodelAccModalCrossDiv">
            <p
              className="ApplicantRepodelAccModalCrossPara"
              onClick={()=>{closeFailModal();setFdbckPopup(true)}}
            >
              ×
            </p>
          </div>
        </div>
        <div>
          <Lottie
            animationData={Oops}
            loop={true}
            className="ApplicantRepodelAccModalErrLottie"
          />
        </div>
        <div className="ApplicantRepodelAccModalErrMsg">{error}</div>
        <div className="modalOkBtnDiv">
        <button className="modalOkBtn" onClick={()=>{closeFailModal();setFdbckPopup(true)}} data-testid='closeFailedbackModal'>OK</button>
        </div>
      </Modal>
      <Modal
        isOpen={successPopup}
        onRequestClose={() => setSuccessPopup(false)}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv">
            <p onClick={() => setSuccessPopup(false)} className="delAccModalCrossPara">×</p>
          </div>
        </div>
        <div>
          <Lottie
            animationData={completed}
            loop={true}
            className="delAccModalErrLottie"
          />
        </div>
        <div className="delAccModalErrMsg">
          {popupMsg}
        </div>
        <div className="okBtnModal">
          <button onClick={() => {setFdbckPopup(false);setSuccessPopup(false)}}  data-testid='successPopupclose'>OK</button>
        </div>
      </Modal>
      <Modal
        isOpen={openSidebar}
        onRequestClose={()=>setOpenSidebar(false)}
        ariaHideApp={false}
        style={sidebarStyle}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="delAccModalCrossDiv" style={{position:"absolute",right:7,top:7}}>
            <p style={{margin:0}} onClick={()=>{setOpenSidebar(!openSidebar)}} className="delAccModalCrossPara" data-testid='closeSidebar'>×</p>
        </div>
        <div className='sidebarLogoAndTabsDiv'>
         <img src={QuikHireLogo} alt='quikhirelogo' onClick={()=>{navigate('/posterDashboard');setSubMenu(false);setSubMenuJob(false)}}/>
         <div className='pDashNavContainer'>
        <div className='pDashSidebarItems' style={{backgroundColor:selectedCard==ModuleCards.Dashboard?"#007749":"#ffff",color:selectedCard== ModuleCards.Dashboard ?"#ffff":"#9D9D9D"}} onClick={()=>{setSelectedCard(ModuleCards.Dashboard);clearSession();navigate("/dashboard");setSubMenu(false);setSubMenuJob(false);setOpenSidebar(!openSidebar)}}>
          <div className="pDashSidebarImgDiv">
            <img src={selectedCard==ModuleCards.Dashboard? dashIconWhite:dashIconGrey} alt='dashboardcard'/>
          </div>
          <div className="pDashSidebarNavs">
          Dashboard
          </div>
        </div>
        {/* <div className='pDashSidebarItems' style={{backgroundColor:selectedCard==ModuleCards.JobRepository?"#007749":"#ffff",color:selectedCard==ModuleCards.JobRepository?"#ffff":"#9D9D9D"}} onClick={()=>{setSelectedCard(ModuleCards.JobRepository);
        clearSession();
        navigate("/dashboard/joblist");
        setSubMenu(false)
        }}>
          <div className="pDashSidebarImgDiv">
            <img src={selectedCard==ModuleCards.JobRepository ? sidebarLogo2:jobRepoGray} />
          </div>
          <div className="pDashSidebarNavs">
          My Jobs
          </div>
        </div> */}


{/* <><div className='pDashSidebarItems' style={{marginBottom:0,backgroundColor:selectedCard==ModuleCards.JobRepository ? "#007749":"#ffff",color:selectedCard==ModuleCards.JobRepository ? "#ffff":"#9D9D9D"}} onClick={()=>{;
          clearSession();
          setSubMenuJob(!subMenuJob);
          setSubMenuColorMyJobs(0)
          setSubMenu(false)
          }}>
          <div className="pDashSidebarImgDiv">
            <img src={selectedCard==ModuleCards.JobRepository ? sidebarLogo2: jobRepoGray} />
          </div>
          <div className="pDashSidebarNavs">
          Jobs &nbsp;<IoMdArrowDropdown className="sidebarDropdownArrow"/>
          </div>
        </div>
        {
           subMenuJob==true &&<>
           <div onClick={() => {clearSession();navigate("/dashboard/joblist");setSubMenuColorMyJobs(2);setSelectedCard(ModuleCards.JobRepository);setOpenSidebar(!openSidebar)}} className='pDashSidebarItems' style={{backgroundColor:'#fff',padding:"7px",width:"80%",alignSelf:"end",marginBottom:0,borderRadius:0}}>
              <div className="pDashSidebarNavs hoverEffect" style={{color:subMenuColorMyJobs==2?"#007749":"#9D9D9D",fontWeight:subMenuColorMyJobs==2?500:"normal"}}>
              <IoMdArrowDropright className="sidebarDropdownRightArrow"/> &nbsp;My Jobs
              </div>
            </div>
            <div  onClick={() => {clearSession();navigate("/dashboard/management/alljobs");setSubMenuColorMyJobs(1);setSelectedCard(ModuleCards.JobRepository);setOpenSidebar(!openSidebar)}} className='pDashSidebarItems' style={{backgroundColor:'#fff',padding:"5px 7px 7px 7px",width:"80%",alignSelf:"end",marginBottom:0,borderRadius:0}}>
              <div className="pDashSidebarNavs hoverEffect" style={{color:subMenuColorMyJobs==1?"#007749":"#9D9D9D",fontWeight:subMenuColorMyJobs==1?500:"normal"}}>
              <IoMdArrowDropright className="sidebarDropdownRightArrow"/> &nbsp;Company Jobs
              </div>
            </div>
            </>
        }
        </> */}

<div className='pDashSidebarItems' style={{backgroundColor:selectedCard==ModuleCards.JobRepository ? "#007749":"#ffff",color:selectedCard==ModuleCards.JobRepository ? "#ffff":"#9D9D9D"}} onClick={()=>{setSelectedCard(ModuleCards.JobRepository );
          clearSession();
          setDropDownVisible(false)
          navigate("/dashboard/joblist");
          setSelectedCard(ModuleCards.JobRepository);
          setOpenSidebar(!openSidebar)
          }}>
          <div className="pDashSidebarImgDiv">
            <img src={selectedCard==ModuleCards.JobRepository ? myJobWhiteNew:myJobGrayNew} />
          </div>
          <div className="pDashSidebarNavs">
          My Jobs
          </div>
        </div>
        <div className='pDashSidebarItems' style={{backgroundColor:selectedCard==ModuleCards.CompanyJobs ? "#007749":"#ffff",color:selectedCard==ModuleCards.CompanyJobs ? "#ffff":"#9D9D9D"}} onClick={()=>{
          clearSession();
          setSelectedCard(ModuleCards.CompanyJobs);
          setDropDownVisible(false)
          navigate("/dashboard/management/alljobs");
          setOpenSidebar(!openSidebar)
          }}>
          <div className="pDashSidebarImgDiv">
            <img src={selectedCard==ModuleCards.CompanyJobs ? companyJobsWhite:companyJobsGray} />
          </div>
          <div className="pDashSidebarNavs">
          Company Jobs
          </div>
        </div>
        
        <div className='pDashSidebarItems' style={{backgroundColor:selectedCard==ModuleCards.ApplicantRepository ? "#007749":"#ffff",color:selectedCard==ModuleCards.ApplicantRepository ? "#ffff":"#9D9D9D"}} onClick={()=>{setSelectedCard(ModuleCards.ApplicantRepository );
        clearSession();
          navigate('/dashboard/applicantRepository');
          setSubMenu(false)
          setSubMenuJob(false);
          setOpenSidebar(!openSidebar)
          // navigate('/dashboard/management/userManagement')
          }}>
          <div className="pDashSidebarImgDiv">
            <img src={selectedCard==ModuleCards.ApplicantRepository ? sidebarLogo3:UserIconGrey} />
          </div>
          <div className="pDashSidebarNavs">
          Applicant Search
          </div>
        </div>
        
        {/* <div className='pDashSidebarItems' style={{backgroundColor:selectedCard==ModuleCards.AllJobs?"#007749":"#ffff",color:selectedCard==ModuleCards.AllJobs?"#ffff":"#9D9D9D"}} onClick={()=>{setSelectedCard(ModuleCards.AllJobs);
        clearSession();
        navigate("/dashboard/management/alljobs")
        setSubMenu(false)
        setSubMenuJob(false);
        }}>
          <div className="pDashSidebarImgDiv">
            <img src={selectedCard==ModuleCards.AllJobs ? sidebarLogo2:jobRepoGray} />
          </div>
          <div className="pDashSidebarNavs">
          All Jobs
          </div>
        </div> */}
        <div className='pDashSidebarItems' style={{backgroundColor:selectedCard==ModuleCards.CompanyList?"#007749":"#ffff",color:selectedCard==ModuleCards.CompanyList?"#ffff":"#9D9D9D"}} onClick={()=>{setSelectedCard(ModuleCards.CompanyList);
        clearSession();
        navigate("/dashboard/companyList")
        setSubMenu(false)
        setOpenSidebar(!openSidebar)
        }}>
          <div className="pDashSidebarImgDiv">
            <img src={selectedCard==ModuleCards.CompanyList ? cmWhite:cmGray} />
          </div>
          <div className="pDashSidebarNavs">
          Company Management
          </div>
        </div>
        <div className='pDashSidebarItems' style={{backgroundColor:selectedCard==ModuleCards.Report?"#007749":"#ffff",color:selectedCard==ModuleCards.Report?"#ffff":"#9D9D9D"}} onClick={()=>{setSelectedCard(ModuleCards.Report);
        clearSession();
        navigate("/dashboard/total-usage-summary")
        setSubMenu(false)
        setOpenSidebar(!openSidebar)
        setSubMenuJob(false)
        }}>
          <div className="pDashSidebarImgDiv">
            <img src={selectedCard==ModuleCards.Report ? sidebarLogoReportwhite:sidebarLogoReportgray} />
          </div>
          <div className="pDashSidebarNavs">
          Total Usage Summary
          </div>
        </div>
        {PortalUserRole=="ROLE_MANAGER" && <><div className='pDashSidebarItems' style={{marginBottom:0,backgroundColor:selectedCard==ModuleCards.Management ? "#007749":"#ffff",color:selectedCard==ModuleCards.Management ? "#ffff":"#9D9D9D"}} onClick={()=>{;
          clearSession();
          // navigate('/dashboard/management');
          setSubMenu(!subMenu);
          setSubMenuColor(0)
          }}>
          <div className="pDashSidebarImgDiv">
            <img src={selectedCard==ModuleCards.Management ? managementIconGray: managementIconWhite} />
          </div>
          <div className="pDashSidebarNavs">
          Team Management &nbsp;<span id={subMenu==true?'downArrow':'rightArrow'}>&gt;</span>
          </div>
        </div>
        {
           subMenu==true &&<>
            <div  onClick={() => {navigate("/dashboard/management/alljobs");setSubMenuColor(1);setSelectedCard(ModuleCards.Management)}} className='pDashSidebarItems' style={{backgroundColor:'#fff',padding:"7px",width:"75%",alignSelf:"end",marginBottom:0,borderRadius:0}}>
              <div className="pDashSidebarNavs hoverEffect" style={{fontSize:"13px",color:subMenuColor==1?"#007749":"#9D9D9D",fontWeight:subMenuColor==1?500:"normal"}}>
              &gt; &nbsp;Company Jobs
              </div>
            </div>
            <div onClick={() => {navigate("/dashboard/management/userManagement");setSubMenuColor(2);setSelectedCard(ModuleCards.Management)}} className='pDashSidebarItems' style={{backgroundColor:'#fff',padding:"7px",width:"75%",alignSelf:"end",marginBottom:0,borderRadius:0}}>
              <div className="pDashSidebarNavs hoverEffect" style={{fontSize:"13px",color:subMenuColor==2?"#007749":"#9D9D9D",fontWeight:subMenuColor==2?500:"normal"}}>
              &gt; &nbsp;User Admin
              </div>
            </div>
            <div onClick={()=>{navigate('/dashboard/management/reportdashboard');setSubMenuColor(3);setSelectedCard(ModuleCards.Management)}} className='pDashSidebarItems' style={{backgroundColor:'#fff',padding:"7px",width:"75%",alignSelf:"end",marginBottom:0,borderRadius:0}}>
              <div className="pDashSidebarNavs hoverEffect" style={{fontSize:"13px",color:subMenuColor==3?"#007749":"#9D9D9D",fontWeight:subMenuColor==3?500:"normal"}}>
              &gt; &nbsp;Reports
              </div>
            </div>
            </>
        }
        </>}
        {/* <div className='pDashSidebarItems' style={{backgroundColor:selectedCard==ModuleCards.profile ? "#007749":"#ffff",color:selectedCard==ModuleCards.profile ? "#ffff":"#9D9D9D"}} onClick={()=>{setSelectedCard(ModuleCards.profile );
        clearSession();
          navigate('/dashboard/profile')}}>
          <div className="pDashSidebarImgDiv">
            <img src={selectedCard==ModuleCards.profile ? ProfileIconWhite:ProfileIconGray} />
          </div>
          <div className="pDashSidebarNavs">
          Profile
          </div>
        </div> */}
        {/* <div className='pDashSidebarItems' style={{backgroundColor:selectedCard==ModuleCards.Notification?"#007749":"#ffff",color:selectedCard==ModuleCards.Notification?"#ffff":"#9D9D9D"}} onClick={()=>{setSelectedCard(ModuleCards.Notification);
          clearSession();
          navigate('/dashboard/notification')}}>
          <div className="pDashSidebarImgDiv">
            <img src={selectedCard==ModuleCards.Notification?bellIconWhite:bellIconGray} />
          </div>
          <div className="pDashSidebarNavs">
          Notifications
          </div>
        </div> */}
      </div>
        </div>
        <div style={{position:"absolute",bottom:"20px",width:"100%"}}>
        <div className='pDashSidebarBottomDiv'>
            {/* <img src={createJob} className="pDashSidebarCreateJobs" alt='Something went wrong' onClick={()=>{setSelectedCard(2);navigate('/dashboard/createJobs');setOpenSidebar(!openSidebar)}} /> */}
            <div className="pDashSidebarCreateJobs" id='createJob' onClick={()=>{setSelectedCard(2);navigate('/dashboard/createJobs');setOpenSidebar(!openSidebar)}}>
          <img alt='createjobbtn' src={qIcon} />Create a job
         </div>
        {/* <div className='pDashSidebarCopyright' style={{border:"1px solid red"}}> */}
          <div className='pDashSidebarCopyright' style={{textAlign:"center"}}>&copy; {new Date().getFullYear()} QuikHire.ai</div>
          <div className='pDashSidebarCopyright' style={{textAlign:"center"}}>All rights reserved.</div>
        {/* </div> */}
      </div>
      </div>
      </Modal>
    </div>
  )
}

export default DashboardHeader