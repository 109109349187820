import React, { useCallback, useEffect, useState, useFocusEffect } from "react";
import { ComponentAndScreenConstant } from "../../constants/componentAndScreenConstant";
import "./Dashboard.css";
import { PortalUserRole, Url } from "../../EnvoirnmentSetup/APIs";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import dashboardempimg from '../../assets/dash_employees.png'
import { useSelectedCardContext, useSelectedSubMenuContext, useSelectedSubMenuJobContext } from "../../Context/Context";
import { ModuleCards } from "../../constants/ModuleCards";
import qsubmit from '../../assets/QSubmit.svg'
import logOutLogo from '../../assets/logoutBtnNew.svg'
import LogOutLogo from '../../assets/logOutLogo.svg'
import Modal from "react-modal";
import Lottie from "lottie-react";
import loader from "../../../src/assets/PANLoader.json";

const Dashboard = () => {
  const navigate = useNavigate();
  const [popupMsg, setPopupMsg] = useState("")
  const [fromDate, setFromDate] = useState(new Date(new Date().setHours(0, 0, 0, 0) - 7 * 24 * 60 * 60 * 1000));
  const [toDate, setToDate] = useState(new Date());
  const [fetching, setFetching] = useState(false);
  const [lgOutPopup, setLgOutPopup] = useState(false)
  const [termsAndCndtnsModal,setTermsAndCndtnsModal] = useState(false)
  const [userId,setUserId] = useState()
  const [errPopup, setErrPopup] = useState(false)
  const [fetchAgain,setFetchAgain] = useState(false)
  const [posterData, setPosterData] = useState({
    totalJobsPosted: 0,
    totalJobsDeleted: 0,
    totalJobsExpired: 0,
    totalJobsActive: 0,
    totalNotifications: 0,
    uniqueApplicantsViewed: 0,
    uniqueApplicantsNotified: 0,
    uniqueApplicantsApplied: 0,
    uniqueApplicantProfiles: 0,
    totalApplicantsContacted: 0,
    activeCompanies:0
  })
  const [userData, setUserData] = useState({
    token: "",
  });
  const {subMenu, setSubMenu, subMenuColor, setSubMenuColor} = useSelectedSubMenuContext();
  const {subMenuJob, setSubMenuJob, subMenuColorMyJobs, setSubMenuColorMyJobs} = useSelectedSubMenuJobContext();
  let usersName =  sessionStorage.getItem("userName")
  usersName = JSON.parse(usersName)

  useEffect(() => {
    getDataByDates()
    // getUserDataname()
  }, [fetchAgain])

  const { selectedCard, setSelectedCard } = useSelectedCardContext();
  useEffect(()=>{
    setSelectedCard(ModuleCards.Dashboard)
    setSubMenu(false)
    setSubMenuJob(false)
  },[])
 
  const termsAndConditionsStyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      width: window.screen.width>1023? "45%" : "82%",
      borderRadius: "25px",
      padding: window.screen.width>1023?"20px":"10px",
    },
  };
  const customStyles = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "40%",
      borderRadius: "25px",
      padding: "10px",
      maxWidth:window.screen.width>1023?'60%':'90%'
    },
  };
  const ApplictionActionloaderstyle = {
    content: {
      top: "50%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      // marginRight: '-50%',
      transform: "translate(-50%, -50%)",
      minWidth: "500px",
      borderRadius: "25px",
      border: "none",
      padding: "10px",
      backgroundColor: "transparent ",
    },
    overlay: {
      backgroundColor: "rgba(0, 0, 0, 0.7)",
    },
  };

  const handleLogout = () => {
    sessionStorage.clear()
    navigate('/login')
  }

  const getUserDataname = () => {
    let token = sessionStorage.getItem("token");
      fetch(Url.getuser, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
      })
        .then((resp) => resp.json())
        .then((res) => {
          setUserId(res?.DATA?.userId)
          // setValidTill(moment(res?.DATA?.validTill).format('DD-MMM-YYYY'))
          if(!res?.DATA?.termsAndConditions){
            setTermsAndCndtnsModal(true)
          }
          else{
            setTermsAndCndtnsModal(false)
          }
        })
        .catch((e) => {
          console.log(e);
        });
  };

  const getDataByDates = () => {
    setFetching(true)
    //   console.log("date",moment(fromDate).format("yyyy-MM-DD"))
      let token = sessionStorage.getItem("token");

      fetch(Url?.getPosterDashboardDetails.replace("{fromDate}", moment(fromDate).format("yyyy-MM-DD")).replace("{toDate}", moment(toDate).format("yyyy-MM-DD")), {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token
        },
      })
        .then((response) => response.json())
        .then((res) => {
          console.log({ res })
          if (res.SUCCESS) {
            setFetching(false)
            setPosterData({
              totalJobsPosted: res?.DATA?.totalJobsPosted,
              totalJobsDeleted: res?.DATA?.totalJobsDeleted,
              totalJobsExpired: res?.DATA?.totalJobsExpired,
              totalJobsActive: res?.DATA?.totalJobsActive,
              totalNotifications: res?.DATA?.totalNotifications,
              uniqueApplicantsViewed: res?.DATA?.uniqueApplicantProfiles,
              uniqueApplicantsNotified: res?.DATA?.uniqueApplicantsNotified,
              uniqueApplicantsApplied: res?.DATA?.uniqueApplicantsApplied,
              uniqueApplicantProfiles: res?.DATA?.uniqueApplicantsViewed,
              totalApplicantsContacted: res?.DATA?.totalApplicantsContacted,
              activeCompanies:res?.DATA?.activeCompanies
            })
          }
          else{
            setFetching(false)
          }
        }).catch((e)=>{
          console.log("Experience", e);
          setFetching(false)
        });
  };
 
  useEffect(() => {
    const getUserData = () => {
      let token = sessionStorage.getItem("token");
      let userrole = sessionStorage.getItem("role");
      let username = sessionStorage.getItem("user_name");
      setUserData({ ...userData, token: token });
      if (token?.length > 0) {
        // navigate('/joblist')
        return;
      } else {
        navigate("/login");
      }
    };
    getUserData();


  }, []);

  const handleAcceptTermsAndConditions = () => {
    setFetching(true)
    let token = sessionStorage?.getItem('token')
      const obj1 = {
        "userId": userId,
        "termsAndConditions": true
      }  
    fetch(Url?.acceptTermsAndConditions,{
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Bearer " + token,
        },
        body:JSON?.stringify(obj1)
      })
      .then((resp)=>resp?.json())
      .then((res)=>{
        if(res?.SUCCESS){
          setFetching(false)
          setTermsAndCndtnsModal(false)
          setFetchAgain(new Date())
        }
        else{
          setFetching(false)
          setPopupMsg(res?.message)
          setErrPopup(true)
        }
      })
      .catch((e)=>{
        setFetching(false)
        console.log(e);
      })
  }

  const handleMailTo = () => {
    window.location.href = 'mailto:support@quikhire.ai';
  }

  return (
    <div >

      {/* GO Back functionality */}
      {/* <div style={{ display: "flex", width: "100%", justifyContent: "flex-end" }}><p onClick={() => { navigate(-1) }} style={{ padding: "20px" }}>Back to homepage</p></div> */}


      <div className="Dashboardtopimg">
        <div className="dashboardimgdiv">
          <div className="dashboardEmpContent">
            <p>Hello, </p>
            <h1 className="dashboardUserName">{usersName}</h1>
          </div>

          <div className="dasboardempimg">
            <img src={dashboardempimg} alt="" />
          </div>

        </div>
        <div className="posterreportMiddleContainerWrapper" >
          <p className="dashboarddataselect">Please select the date range to filter the data.</p>
          <div className="dashboardDates">
            <div className="posterreportFromDate" >
              <p>From Date:</p>
              <ComponentAndScreenConstant.DatePickerComponent
                placeholderText="Enter here"
                label={""}
                // minDate={new Date()}
                maxDate={new Date()}
                isRequired={true}
                InputSty={{
                  backgroundColor: "#FFF",
                  textAlignVertical: "top",
                  // height: 100,
                  color: "#222B45",
                  fontSize: 14,
                }}
                selectedDate={new Date(fromDate)}
                onChange={(e) => {
                  console.log(e);
                  setFromDate(e);
                }}
                minYearsRequired={6}
              />
            </div>
            <div className="posterreportFromDate">
              <p >To Date:</p>
              <ComponentAndScreenConstant.DatePickerComponent
                placeholderText="Enter here"
                label={""}
                // minDate={new Date()}
                maxDate={new Date()}
                isRequired={true}
                InputSty={{
                  backgroundColor: "#FFF",
                  textAlignVertical: "top",
                  // height: 100,
                  color: "#222B45",
                  fontSize: 14,
                }}
                minDate={fromDate}
                selectedDate={new Date(toDate)}
                onChange={(e) => {
                  console.log(e);
                  setToDate(e);
                }}
                minYearsRequired={6}
              />
            </div>
          </div>
          <button
            className="posterSubmitReportDates"
            onClick={() => {
              getDataByDates();
            }}
          >
            <img src={qsubmit} alt="something went wrong"/>
          </button>
        </div>
      </div>
      <div className='posterreportUpperContainerWrapper'>
        {/* <div className='posterreportUpperContainerLeftWrapper'> */}
        <div className='posterreportentryName'>
          <p className='DashboardCountContainer'>{posterData?.totalJobsPosted}</p><p className='DashboardCountContainertitle'>Jobs Posted</p>
        </div>
        <div className='posterreportentryName'>
        <p className='DashboardCountContainer'>{posterData?.totalJobsActive}</p><p className='DashboardCountContainertitle'>Current Active Jobs</p>
        </div>
        <div className='posterreportentryName'>
          <p className='DashboardCountContainer'>{posterData?.totalJobsExpired}</p><p className='DashboardCountContainertitle'>Jobs Expired</p>
        </div>
        <div className='posterreportentryName'>
        <p className='DashboardCountContainer'>{posterData?.totalJobsDeleted}</p><p className='DashboardCountContainertitle'>Jobs Deleted</p>
        </div>
        <div className='posterreportentryName'>
          <p className='DashboardCountContainer'>{posterData?.totalNotifications}</p><p className='DashboardCountContainertitle'>Notifications</p>
        </div>
        {/* </div> */}
        {/* <div className='posterreportUpperContainerRightWrapper'> */}
        <div className='posterreportentryName'>
          <p className='DashboardCountContainer'>{posterData?.uniqueApplicantsViewed}</p><p className='DashboardCountContainertitle'>Applicants Viewed</p>
        </div>
        <div className='posterreportentryName'>
          <p className='DashboardCountContainer'>{posterData?.uniqueApplicantsNotified}</p><p className='DashboardCountContainertitle'>Applicants Notified Through Job Posting</p>
        </div>
        <div className='posterreportentryName'>
          <p className='DashboardCountContainer'>{posterData?.uniqueApplicantsApplied}</p><p className='DashboardCountContainertitle'>Candidates Applied</p>
        </div>
        {/* <div className='posterreportentryName'>
          <p className='DashboardCountContainer'>{posterData?.uniqueApplicantProfiles}</p><p className='DashboardCountContainertitle'>Linkedin Profiles Views</p>
        </div> */}
        <div className='posterreportentryName'>
          <p className='DashboardCountContainer'>{posterData?.totalApplicantsContacted}</p><p className='DashboardCountContainertitle'>Applicants Contacted</p>
        </div>
        <div className='posterreportentryName'>
          <p className='DashboardCountContainer'>{posterData?.activeCompanies}</p><p className='DashboardCountContainertitle'>Total Registered Companies</p>
        </div>
        {/* <div className='posterreportentryName' style={{visibility:window.screen.width>1020 && "hidden",display:window.screen.width<1020 && "none"}}>
          <p className='DashboardCountContainer'>{posterData?.activeCompanies}</p><p className='DashboardCountContainertitle'>Total Registered Companies</p>
        </div> */}
        {/* </div> */}
      </div>
      <Modal
        isOpen={termsAndCndtnsModal}
        onRequestClose={()=>setTermsAndCndtnsModal(false)}
        ariaHideApp={false}
        style={termsAndConditionsStyle}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
        shouldCloseOnOverlayClick={false}
      > 
        <h2 className="tandcHeading">Terms and Conditions</h2>
        <p className="tandcPara tandcParaHeading">By using Quikhire.ai as a recruiter/hiring platform, you agree to the following terms and conditions:</p>
        <p className="tandcPara">You must provide accurate information during registration and maintain the confidentiality of your account. Job postings must be lawful, accurate, and non-discriminatory. You are responsible for complying with all applicable laws, including data protection regulations, and for conducting recruitment activities professionally and ethically. Quikhire.ai reserves the right to remove any job postings or terminate your account for any breach of these terms. Fees for services must be paid promptly. Quikhire.ai is not liable for any damages resulting from your use of the platform. These terms may be updated, and continued use of Quikhire.ai constitutes acceptance of any changes.</p>
        <p className="tandcPara tandcParaFooter">For any queries, contact: <span onClick={()=>handleMailTo()}>support@quikhire.ai</span>.</p>
        <div className="TandAbtns">
         <button onClick={()=>setLgOutPopup(true)}>Decline</button>
         <button onClick={handleAcceptTermsAndConditions}>I Agree</button>
        </div>
      </Modal>
      <Modal
        isOpen={lgOutPopup}
        onRequestClose={() => setLgOutPopup(false)}
        ariaHideApp={false}
        style={customStyles}
        contentLabel="Example Modal"
        overlayClassName="Overlay"
      >
        <div className="delAccModal1">
          <div className="delAccModalCrossDiv" style={{ backgroundColor: "#FFF4F4" }}>
            <p onClick={() => setLgOutPopup(false)} className="delAccModalCrossPara" style={{ color: "#BC0000" }}>×</p>
          </div>
        </div>
        <div className='logoutLogoDiv'>
          <img src={LogOutLogo} />
        </div>
        <div className="delAccModalErrMsg" style={{ fontWeight: "normal",marginBottom:0}}>
        Would you like to logout?
        </div>
        <div className="okBtnModal" style={{ display: "flex", gap: "2%" }}>
          <button onClick={() => setLgOutPopup(false)} style={{ backgroundColor: "#ffff", border: "0.5px solid #007749", color: "#007749",cursor:"pointer" }}>Cancel</button>
          <button style={{ backgroundColor: "#BC0000",cursor:"pointer" }} onClick={handleLogout}>Logout</button>
        </div>
      </Modal>
      <Modal
        isOpen={fetching}
        ariaHideApp={false}
        style={ApplictionActionloaderstyle}
        contentLabel="Example Modal"
      >
        <Lottie
          animationData={loader}
          loop={true}
          style={{ width: "100px", height: "100px", margin: "0px auto" }}
        />
      </Modal>
    </div>
  );
};

export default Dashboard;
